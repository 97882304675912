 @import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');
 @import url('https://fonts.cdnfonts.com/css/satoshi');

:root {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

body {
  margin: 0;
  font-family: 'Cabinet Grotesk', sans-serif;                                            
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff !important;

}

nav {
  background: #ffffff !important;
}

ul {
  justify-content: center;
  align-items: center;
  /* margin-left: -95px; */
}

ul li {
  margin-left: 40px;
  letter-spacing: 1.2px;
  font-size: 16px;
  font-family: 'Satoshi', sans-serif;
  color: #31869d;
}
.img2 {
  width: 100px;
  height: auto;
  margin-left: 30px;
}
a {
  font-family: 'Satoshi', sans-serif;
  color: #31869d !important;
  font-size: 17px;
  font-weight: 400;
}

.navbar {
  box-shadow: none !important;
}

.page-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
}

.banner-header p {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1;
  max-width: 933px;
  text-align: center;
  color: #101828;
  margin: 0px 0px 24px;

}



.banner-text p {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 30px;
  max-width: 616px;
  text-align: center;
  color: #475467;
  margin: 0px 0px 24px;
  letter-spacing: 1.4px;
}

.banner-buttons {
  /*   background: black; */
   /* background: black; */
   color: #ffffff;
   letter-spacing: 1.6px;
   font-size: 15px;
   text-transform: uppercase;
   font-weight: bold;
   display: block;
   padding: 5px 40px;
   border-radius: 9px;
   box-shadow: none;
   background-color: none;
   cursor: pointer;
   text-decoration: none;
   border: 1px solid  #31869d;
   background: transparent;
   background-position: 100% 0px;
   background-size: 200% 100%;
   transition: 0.7s ease-in-out;
   margin-bottom: 10px;
   height: 35px;
   margin-top: 33px;
   background: #31869d;
}
.button p {
  text-decoration: none;
  color: #000000;
}

.btn {
  padding: 22px 60px !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  letter-spacing: 1.2px;
  background: #31869d !important;

}

.animating-text {
  animation: gradient-text 10s linear infinite reverse;
  background-image: linear-gradient(90deg,#31869d,#14bf93,#14a8a6,#149ab0,#1467d8);
  background-size: 400%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.img {
  object-fit: contain;
  border-radius: 12px;
  max-width: 100% !important;
  height: 467px !important;
  max-width: 768px !important;
}

.slider {
  overflow-x: auto;
    /* background-color: rgb(243 248 255/1); */
    padding: 20px;
    width: 100vw;
}


.animate-marquee-left {
    animation: moveSlideshow 10s linear infinite;
    opacity: 1;
    transition: opacity 0.5s ease-out;
    background-position: 0 -100px;
    margin-top: 30px;
    margin-bottom: 40px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

@keyframes moveSlideshow {
  100% { 
    transform: translateX(-66.6666%);  
  }
}

.sponsor-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  letter-spacing: 1.2px;
  font-weight: 800;
}

section {
	display:flex;
	flex-flow:column;
	align-items:center;

}
section div .container {
  transition:all .3s ease;


}

.container   h1 { margin:15px 0 0 0; }

.container   h3 { margin:0 0 25px 0; }

@media (max-width:992px) { 
  section{
    padding:0 20px 0 20px;
  }

 }

/* section .slide-option {
	margin:0 0 50px 0;

} */

.no-marg { margin:0 0 0 0; }

section.slide-option {
  margin: 0 0 50px 0;
}
section.slide-option .no-marg {
  margin: 0 0 0 0;
}
div.highway-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
}
div.highway-slider div.highway-barrier {
  overflow: hidden;
  position: relative;
}
div.highway-slider ul.highway-lane {
  display: flex;
  height: 100%;
}
div.highway-slider ul.highway-lane li.highway-car {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #fff; */
  color: #343434;
}
@keyframes translatestf {
  0% {
    transform: translateX(100%);
 }
  100% {
    transform: translateX(-500%);
 }
}
#stffull div.highway-barrier ul.highway-lane {
  width: 500%;
}
#stffull div.highway-barrier ul.highway-lane li.highway-car {
  animation: translatestf 30s linear infinite;
}
#stffull div.highway-barrier ul.highway-lane li.highway-car h4 {
  font-size: 28px;
}
@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-2160px));
 }
}
#infinite div.highway-barrier {
  /* background: #fff; */
  /* box-shadow: 0 3px 10px -3px rgba(0,0,0,0.3); */
}
#infinite div.highway-barrier::before, #infinite div.highway-barrier::after {
  content: " ";
  position: absolute;
  z-index: 9;
  width: 180px;
  height: 100%;
}
#infinite div.highway-barrier::before {
  top: 0;
  left: 0;
  /* background: linear-gradient(to right,#ffffff 0%,rgba(255,255,255,0) 100%); */
}
#infinite div.highway-barrier::after {
  top: 0;
  right: 0;
  /* background: linear-gradient(to left,#ffffff 0%,rgba(255,255,255,0) 100%); */
}
#infinite div.highway-barrier ul.highway-lane {
  width: calc(4320px);
}
#infinite div.highway-barrier ul.highway-lane li.highway-car {
  width: 180px;
  animation: translateinfinite 25s linear infinite;
}
#infinite div.highway-barrier ul.highway-lane li.highway-car span.fab {
  font-size: 65px;
}

.about-us-banner {
 padding: 12px 10px 0px;

}


.about-us {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* max-width: 800px; */
  /* margin: 0 auto; */
  margin-top: 50px; 
}

.about-us p {
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 1px;
 
}


.about-us h3 {
  line-height: 5.625rem;
  font-size: 4.5rem;
  max-width: 36.8125rem;
  --tw-text-opacity: 1;
  color: rgb(16 24 40/var(--tw-text-opacity));
  font-family: CabinetGrotesk;
  font-weight: 800;
  margin-left: 20px;
}

.our-story {
  max-width: 700px;
  /* margin: 0 auto; */
  margin-left: 60px;
  margin-top: 100px;
}

.our-story h6 {
  color: #31869d;
  font-size: 1.5rem;
  text-transform: capitalize;
  letter-spacing: 0.9px;
}

.our-story h5 {
  font-size: 3rem;
}

.our-story p {
  font-weight: 500;
  letter-spacing: 1px;
}

.mission-vision {
  background-color: #31869d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 70px;
  padding-top: 56px;
  padding-bottom: 56px;
  padding-left: 64px;
  padding-right: 64px;
  border-radius: 20px;
  color: #fff;
  font-family: 'Satoshi', sans-serif;
}

.mission {
  width: 50%;
  height: 340px;
}

.vission span {
  font-weight: BOLD;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-family: 'Satoshi', sans-serif;
}

.mission-vision h4 {
  font-size: 2rem;
  letter-spacing: 1px;
}

.react-tabs__tab-list {
  border-bottom: none !important;
}

.react-tabs__tab {
  /* border: none !important; */

}

.react-tabs__tab--selected {
  border-top: none !important;
  border-left: none !important;
  /* border-right: none !important; */
  border-bottom: 1px solid black !important;

}

.react-tabs__tab-panel--selected  {
  padding: 20px;
}

.container-2{
  position: relative;
  /* width: 50%; */
  max-width: 600px;
  width: 500px;
}

.container{
  position: relative;
  /* width: 50%; */
  max-width: 300px;
  width: 300px;
}

.image {
  display: block;
  width: 108%;
  height: 250px !important;
  transition: .5s ease;
  backface-visibility: hidden;
  margin: 0 auto;

}


.container:before:hover{
  opacity: 0.5;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-80%, -10%);
  -ms-transform: translate(-40%, -50%);
  text-align: center;
  width: 240px;
}

.container:hover .image {
  filter: opacity(0.6) drop-shadow(0 0 0 #000); 
}

.container:hover .middle {
  opacity: 1;
}

.middle span {
  margin-bottom: 5px;
  font-size: 15px;
  color: white;
  text-transform: uppercase;
  width: 100%;
  display: block;
  font-weight: 700;
}

.text {
 cursor: pointer;
  color: white !important;
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 3px;
  transition: .5s ease-in-out;
  border: 1px solid black;
  letter-spacing: 1.2px;
  font-weight: 700;
}

.text:hover{
  background-color: #000;
  color: white !important;
  opacity: 0.5
}

.tab-grid-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, 1fr);
}

.single-project {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
  
}

 h2 {
  letter-spacing: 1.1px;
  font-family: 'Cabinet Grotesk', sans-serif;   
  color: #ffffff;
  text-align: start;
  font-size: 36px;
  padding: 35px 39px;
  background-color: #31869d;
  border-radius: 5px;
  width: 100%;
}

.project-heading {
  border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    margin-bottom: 10px;
    letter-spacing: 1.1px;
    font-family: 'Satoshi', sans-serif;

}

.single-project-info {
  width:40%
}

.single-project-info p {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;

}

.lg-react-element {
  display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;
}

.single-project-gallery {
  
  width: 70%
}

.img-galley {
  object-fit: contain;
  border-radius: 12px;
  /* max-width: 100% !important; */
  height: auto;
  width: 200px;
  margin-left: 10px;
  margin-bottom: 20px
}

.tab-grid-container {
  grid-template-columns: repeat(5, 1fr);
}


.css-heg063-MuiTabs-flexContainer {
  justify-content: flex-start;
  margin-left: 7px !important;
  
}

.css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: #31869d !important;
  color: white !important;
  border-radius: 4px !important;
}

.css-aym9vq-MuiButtonBase-root-MuiTab-root {
  margin-left: 20px !important
}

.seperator {
  border-top: 4px solid #31869d;
    margin: 10px 0;
    padding: 0;
    height: 1px;

}

.content-body h2 {
  background : none !important
}

.content-inner {
  display: flex;
}

.content-inner ul {
  margin: 20px
}

.content-inner li {
  list-style: none;
  margin-bottom: 10px;
}

.css-1aquho2-MuiTabs-indicator {
  display: none !important;
}

.icons {

    margin-left: 170px
  
}

.icons a {
  color: none !important;
  padding: 5px;
  /* background-color: grey;
  margin-left: 5px; */
}

a svg:hover {
  scale: 1.2;
  transition: .5s ease-in-out;
  color: #31869d;
}

.contact-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin: 25px;
  padding: 50px;
}

.contact-container h3 {
  margin-bottom: 10px;
  letter-spacing: 1.2px;
  font-family: 'Cabinet Grotesk', sans-serif;     
  font-size: 30px;
}

.contact-container h4 {
  margin-bottom: 30px;
  margin-top: 20px;
}

.text-wrap {
  margin-bottom: 20px;
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
}

.text-wrap span {
  font-weight: 700;
}

.form-wrapper {
  border: 8px solid #f2f2f2;
  padding: 15px;
  font-family: 'Satoshi', sans-serif;
  display: block;
  margin: 0 auto;
}
/* .css-1nvn3ta-JoyTextarea-root {
  width: 77ch;
} */

.map-frame {
  width: 100%;
  height: 400px;
}




@media (max-width: 600px) {
  .banner-header p {
    font-size: 2rem;
    max-width: 100VW;
    letter-spacing: 1.2px;
  } 
  .banner-text p {
    font-size: 1rem;
    margin: 0px 0px 24px;
    max-width: 400px;
  }
  nav ul {
    padding: 0;
    width: auto !important;
  }
  nav ul li a {
    margin-bottom: 15px;
    letter-spacing: 1.1px;
  }
  .sponsor-text {
    font-size: 17px;
  }
  .mission-vision {
    flex-direction: column;
  }
  .about-us {
    flex-direction: column;

  }
  .about-us-banner {
    padding:10px
  }
  .about-us h3 {
    font-size: 2.1rem;
    line-height: 1;
    margin-left: 0;
    max-width: 100vw;
    margin: 0 auto;
  }
  .about-us img {
    margin: 0 auto;
    width: 100vw;
    max-width: 100% !important;
    height: 600px !important;
    object-fit: cover;
    margin-top: 20px;
  }
  .our-story {
    margin: 0;
  }
  .single-project {
    flex-direction: column;
  }

.single-project-info {
  width:100%
}
.single-project-gallery {
  
  width: 100%
}
.tab-grid-container {
  grid-template-columns: repeat(2, 250px);
}
}

@media (max-width: 1200px) {
  .about-us {
    flex-direction: column;

  }
  .about-us h3 {
    font-size: 2.1rem;
    line-height: 1;
    margin-left: 0;
  }
  .about-us img {
    margin: 0 auto;
    width: 100vw;
    max-width: 100% !important;
    height: 600px !important;
    object-fit: cover;
    margin-top: 20px;
  }
  .contact-container {

    grid-template-columns: repeat(1,1fr);
    margin: 0;
    padding: 14px;
   
  }

  .form-wrapper {
    width: 100%;
  }

  .contact-info {
    margin-left: 95px;
  }


.tab-grid-container {
  grid-template-columns: repeat(3, 1fr);
}

}






